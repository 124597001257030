i.icon {
  outline: none;
}

.post-item__views-count svg,
.post-content__views-count svg,
.profile-posts__views-count svg,
.lesson-content__views-count svg {
  display: inline;
  vertical-align: unset;
}

#quill-container .quill .ql-toolbar {
  border-radius: 0.5rem 0.5rem 0 0;
}

#quill-container .quill .ql-container {
  border-radius: 0 0 0.5rem 0.5rem;
}