/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .rotate-y-180 {
    transform: rotateY(180deg);
  }

  .transform-style-3d {
    transform-style: preserve-3d;
  }

  .backface-visibility-hidden {
    backface-visibility: hidden;
  }

  .card-box-shadow {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
  }

  .card-image-width {
    width: calc(100% - 1px);
  }

  .perspective {
    perspective: 800px;
  }

  .scroll-y-fix {
    padding-right: calc(1rem - (100vw - 100%));
  }
}