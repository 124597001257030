body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 540px) {
  body ::-webkit-scrollbar {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
  }

  body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba(2, 128, 144, 0.5);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }

  body ::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba(0, 0, 0, 0.15);
  }

  body ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(2, 128, 144, 0.8);
  }
}

.ui.loader {
  top: 10% !important;
}

.ui.loader:after {
  border-top-color: rgba(2, 128, 144, 0.5) !important;
}

.content-wrapper {
  max-width: 80rem;
  margin: 0 auto;
}

.account__tabs .ui.secondary.pointing.menu .item {
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 0.857em !important;
}

.account__tabs .ui.secondary.pointing.menu .active.item {
  color: #028090 !important;
  border-color: #028090 !important;
}

.account__tabs .ui.secondary.pointing.menu a.item:hover {
  color: #028090 !important;
}

.account__pane.tab {
  position: relative;
  background-color: #fff;
  box-shadow: none;
  margin: 0;
  padding: 0;
  border-radius: unset;
  border: none;
}

.account__item-label {
  text-transform: uppercase;
  color: #028090;
}

.account-container {
  width: 90%;
  margin: 0 auto;
}

.account__section {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 1.2rem;
}

.account__section-header {
  color: #028090;
  font-weight: 400;
}

.account__section label {
  text-transform: uppercase;
  color: #028090;
}

.account__input-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: unset;
}

.account__btn-group {
  padding-bottom: 1rem;
}

.account__avatar-current {
  display: flex;
  align-items: center;
  color: #028090;
}

.account__avatar-current img {
  margin-left: 0.5rem;
}

.account__avatar-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem;
}

.account__avatar-form .field:last-child {
  margin-bottom: 1em !important;
}

.account__avatar-field {
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.account__avatar-wrapper,
.account__avatar-wrapper img,
.account__avatar-wrapper label {
  cursor: pointer;
}

.account__avatar-label {
  font-weight: 300 !important;
  color: rgba(0, 0, 0, 0.6) !important;
  margin: 0 !important;
  text-transform: uppercase !important;
}

.account__avatar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account__avatar-container-btns {
  display: flex;
}

.account__avatar-gravatar {
  position: absolute;
  bottom: -1em;
  left: 4.5rem;
  text-transform: uppercase;
  text-align: right;
}

.account__avatar-gravatar a {
  font-size: 0.71429rem !important;
  font-weight: 300 !important;
  color: #028090 !important;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out !important;
}

.account__avatar-gravatar a:focus,
.account__avatar-gravatar a:hover {
  color: #007f8e !important;
  font-weight: bolder;
  text-decoration: underline !important;
}

.account .ui.checked.radio.checkbox~.account__avatar-label {
  color: #028090 !important;
  font-weight: 400 !important;
}

.account .ui.radio.checkbox input:checked~label:after {
  background-color: #028090 !important;
}

@media (max-width: 450px) {
  .account__avatar-gravatar {
    bottom: -1.3em;
    left: 0;
  }

  .account__section {
    margin-bottom: 1rem;
  }
}

@media (max-width: 330px) {
  .account-container {
    width: 95%;
  }

  .account__tabs .ui.secondary.pointing.menu .item {
    padding: 0.57em !important;
  }
}

.account-delete__trigger {
  font-weight: 400 !important;
  margin-left: 1rem !important;
}

.account-delete__trigger-label {
  color: #db2828 !important;
}

.account-delete__header {
  color: #028090 !important;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1.285em;
}

.account-delete__input {
  margin-bottom: 1.5rem;
}

.account-delete__input label {
  text-transform: uppercase;
  color: #028090;
}

@media (max-width: 450px) {
  .account-delete__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem !important;
  }

  .account-delete__trigger {
    width: 100% !important;
    margin-top: 1rem !important;
    margin-left: 0 !important;
  }
}

.account-form {
  color: rgba(0, 0, 0, 0.6);
}

.account-form label {
  text-transform: uppercase;
  color: #028090;
}

.account-form__header {
  color: #028090;
  font-weight: 400;
}

.account-form__input {
  margin-bottom: 1.5rem;
}

.account-form__input input:invalid {
  outline-color: #900;
  background-color: #fdd;
}

.account-form__input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1.2rem;
}

.account-form__input-link {
  width: 100%;
}

.account-form__input-link .label {
  border: 1px solid rgba(34, 36, 38, 0.15);
  background-color: #fff;
  color: rgba(34, 36, 38, 0.15);
}

.account-form__input-link input:invalid {
  outline-color: #900;
  background-color: #fdd;
}

.account-form__textarea-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem !important;
}

.account-form__textarea-counter {
  margin-left: auto;
}

.account-form__btn {
  font-weight: 400 !important;
  margin-right: 1rem !important;
  margin-bottom: 1.5rem !important;
}

.account-form__btn-update {
  color: #fff !important;
  cursor: pointer !important;
}

.account-form__btn-cancel {
  color: rgba(0, 0, 0, 0.6) !important;
  cursor: pointer !important;
}

.account-form__btn-delete {
  font-size: 1rem !important;
  padding: 0.785em !important;
  margin: 0 !important;
}

.account-form__btn-delete.ui.button {
  color: rgba(34, 36, 38, 0.15) !important;
}

@media (max-width: 450px) {
  .account-form__input {
    margin-bottom: 1rem;
  }

  .account-form__btn {
    margin-bottom: 0 !important;
  }
}

.alert {
  width: 320px !important;
  min-height: 64px !important;
  z-index: 999;
  position: absolute !important;
  top: 8rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.alert .header {
  color: inherit;
}

.app {
  /* width: 100%; */
  overflow: hidden;
}

.app,
.app-layout {
  height: 100%;
}

.app-main {
  --header-height: 92px;
  height: calc(100vh - var(--header-height));
  position: relative;
}

.app-scroll-wrapper {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  overflow: hidden;
}

.app-scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none;
}

@media screen and (max-width: 540px) {
  .app-main {
    --header-height: 84px;
  }
}

.blog {
  min-height: 100vh;
}

.blog__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  color: #fff;
  background-color: #028090;
  padding: 2.5rem 0;
}

.blog__header-back {
  position: absolute;
  left: 1rem;
}

.blog__header-text {
  font-size: 1.71429rem;
  font-weight: 300;
  letter-spacing: 0.6rem;
}

.blog__feed {
  width: 80%;
  margin: 0 auto;
}

.blog__container {
  padding: 0.5rem;
}

.blog-item__content {
  display: flex !important;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}

.blog-post {
  padding: 1.5rem;
}

.blog-post__byline {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.blog-post__author {
  color: #028090;
}

.blog-post__timestamp {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 1.5rem;
}

.blog-post__content {
  margin-top: 1.5rem;
}

.blog-post__content-img {
  padding-bottom: 1rem;
}

.blog-post__content-ul {
  list-style-type: none;
  -webkit-padding-start: unset !important;
  padding-inline-start: unset !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__post {
    width: 90%;
  }
}

@media only screen and (min-width: 1200px) {
  .blog-post {
    width: 1127px;
    margin-left: auto;
    margin-right: auto;
  }

  .blog__header {
    width: 1127px;
  }
}

.blog-feed__end {
  background-color: initial;
  height: 20px;
}

.blog-feed__end:hover {
  background-color: #e6f5f2;
}

@media screen and (max-width: 1199px) {
  .blog__header {
    width: 100%;
  }
}

.blockchain {
  margin: 4rem 0 3rem;
}

.blockchain__container {
  width: 90%;
  margin: 0 auto;
}

.blockchain__top {
  margin-bottom: 1.5rem;
}

.blockchain__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.blockchain__item-title {
  font-family: 'Poppins', sans-serif;
  font-size: 2.2rem;
  line-height: 1.5em;
  color: #028090;
  text-align: center;
}

.blockchain__item-text {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.5em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.blockchain__logos {
  display: flex;
  align-items: baseline;
}

.blockchain__logos a+a {
  margin-left: 2rem;
}

.chat {
  padding: 1rem;
  height: 100%;
}

.chat .ui.comments .comment .avatar img {
  border-radius: 50% !important;
}

.chat__wrapper {
  height: 100%;
}

.chat__users.sidebar {
  margin-top: 10rem !important;
  height: unset !important;
  min-height: 200px !important;
  padding: 1rem;
  color: #fff;
  background-color: #028090;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.chat__users.sidebar .ui.header {
  margin-bottom: 0 !important;
  color: #fff !important;
}

.chat__users.sidebar.ui.comments .comment {
  display: flex;
  align-items: center;
  line-height: unset !important;
}

.chat__users.sidebar.ui.comments .comment .avatar~.content {
  margin-left: 1em !important;
}

.chat__users.sidebar.ui.comments .comment .username {
  font-size: 1.1rem;
  font-weight: 300;
  color: #fff;
}

.chat__users .title {
  color: #fff;
}

.chat__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat__header-title {
  font-size: 1.7em;
  font-weight: 700;
  color: #028090;
}

.chat__header-users.ui.labeled.button {
  box-shadow: 0 0 20px rgba(82, 111, 114, 0.15) !important;
}

.chat__header-users.ui.labeled.button .ui.icon.button {
  color: #fff;
  background-color: #028090;
}

.chat__header-users.ui.labeled.button .ui.label {
  color: #028090;
  background-color: #fff;
}

.chat__container {
  display: grid;
  grid-template-rows: 11fr 1fr;
  grid-template-areas: 'messages' 'input';
  max-height: 90%;
  margin-top: 1rem;
}

.chat__messages {
  grid-area: messages;
  overflow-y: auto;
}

.chat__username {
  display: flex;
}

.chat__msg-handle,
.chat__notify-username,
.chat__notify-welcome {
  color: #028090;
  font-weight: 700;
}

.chat__notify-welcome {
  font-style: italic;
}

.chat__notify-error {
  color: red;
  font-style: italic;
}

.chat__notify-action {
  font-style: italic;
}

.chat__input {
  grid-area: input;
  display: flex;
  align-items: center;
}

.chat__input .input {
  flex: 1 1;
}

.chat__send {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.chat__send svg {
  margin: auto 0;
  fill: #028090;
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .chat__wrapper {
    padding: 1rem 3rem;
  }
}

@media screen and (min-width: 768px) {
  .chat__wrapper {
    padding: 1rem 10rem;
  }
}

.challenge {
  padding: 1rem;
}

.challenge__tabs {
  margin-top: 0.5rem;
}

.challenge__tabs .ui.secondary.pointing.menu .item {
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 0.857em !important;
}

.challenge__tabs .ui.secondary.pointing.menu .active.item {
  color: #028090 !important;
  border-color: #028090 !important;
}

.challenge__tabs .ui.secondary.pointing.menu a.item:hover {
  color: #028090 !important;
}

.challenge__tabs .ui.menu {
  justify-content: center;
}

.challenge__pane.tab {
  position: relative;
  background-color: #fff;
  box-shadow: none;
  margin: 0;
  padding: 0;
  border-radius: unset;
  border: none;
}

.challenge__response-btn {
  margin-top: 2rem;
}

.challenge .comment-form__buttons {
  margin-top: 1rem;
}

.challenge .ui.button {
  margin: 0 !important;
}

.challenge .ui.button+.ui.button {
  margin-top: 0.8rem !important;
}

.challenge__submission-header h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  text-align: center;
  color: #028090 !important;
}

.challenge__submission-header .message {
  text-align: center;
}

.challenge__submission-form {
  margin-top: 0.5rem;
  text-align: center;
}

.challenge__submission-form .ui.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #028090 !important;
  padding-bottom: 2rem;
}

.challenge__submission-form--input {
  padding: 0.5rem 0;
  width: 100%;
}

.challenge__submission-form--btn {
  width: 100%;
  margin-top: 0.5rem;
  color: #fff !important;
  background-color: #028090 !important;
}

.challenge__submission-form--btn.ui.button {
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
}

.challenge__submission-photo {
  width: 100%;
  margin-top: 0.5rem;
  object-fit: contain;
}

.challenge__submission-photo-remove {
  margin-left: 0.3rem !important;
}

.challenge__responses .title {
  display: flex;
  align-items: center;
}

.challenge__responses .title i {
  font-size: 18px !important;
  color: #028090 !important;
}

.challenge__responses-none {
  text-align: center;
  font-style: italic;
}

.challenge__response-title {
  justify-content: space-between;
  width: 100%;
  color: #028090 !important;
}

.challenge__response-title,
.challenge__response-title-user {
  display: flex;
  align-items: center;
}

.challenge__response-avatar {
  margin-right: 0.5rem;
}

.challenge__response-name {
  font-size: 1.1rem;
  font-weight: 700;
}

.challenge__response-username {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.challenge__response-date {
  font-weight: 400;
  text-transform: uppercase;
}

.challenge__response-status {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.8);
}

.challenge__response-photo {
  width: 100%;
  object-fit: contain;
}

.challenge__response-content {
  margin: 1rem 0;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.8);
}

.challenge__response-delete.ui.button {
  background-color: #db2828;
  color: #fff;
  text-transform: uppercase;
}

.challenge__reward {
  text-align: center;
  color: #028090;
}

.challenge__reward-status {
  padding-left: 3px;
  font-weight: 700;
  text-transform: capitalize;
}

@media screen and (min-width: 501px) {
  .challenge__submission-form--input {
    width: 80%;
  }

  .challenge__submission-form--btns {
    width: 80%;
    margin-top: 1rem;
  }
}

@media screen and (min-width: 600px) {
  .challenge {
    padding: 1rem 3rem;
  }
}

@media screen and (min-width: 768px) {
  .challenge {
    padding: 1rem 10rem;
  }
}

.challenges {
  padding: 1rem;
}

.challenges h2 {
  margin: 0 !important;
  color: #028090 !important;
}

.challenges__container {
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  max-width: 100%;
  margin: 0 auto;
}

.challenges__title {
  margin-top: 0.5rem !important;
  margin-bottom: 0;
  text-align: center;
  color: #028090 !important;
}

.challenges__filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: -webkit-min-content;
  width: min-content;
  color: #028090 !important;
}

.challenges__filter>span {
  text-transform: uppercase;
}

.challenges__filter .ui.selection.dropdown {
  width: -webkit-max-content;
  width: max-content;
  min-width: 150px;
  min-height: 2em !important;
  padding: 0.3rem;
  line-height: 1.43em;
}

.filter-wrapper .ui.selection.dropdown {
  width: -webkit-max-content;
  width: max-content;
  min-width: 7.5rem;
  min-height: 2em !important;
  padding: 0.3rem 0.3rem 0.3rem 1rem;
  line-height: 1.43em;
}

.filter-wrapper .ui.selection.dropdown>.text {
  color: rgba(0, 0, 0, .6);
}

.filter-wrapper .ui.selection.dropdown i.icon,
.challenges__filter .ui.selection.dropdown i.icon {
  float: right;
  padding-left: 0.3rem;
  color: #c4c0c0 !important;
}

.challenges__company {
  font-size: 16px;
  text-align: center;
}

.challenges__company span {
  color: rgba(0, 0, 0, 0.6);
}

.challenges__meta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  color: #028090 !important;
}

.challenges__rewards-number {
  font-weight: 700;
}

.challenges__rewards-label {
  margin-left: 1rem;
}

.challenges__expires {
  color: rgba(0, 0, 0, 0.8);
}

.challenges__extra {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.challenges__category.ui.label {
  text-transform: capitalize;
}

.challenges__status.ui.label {
  margin-left: 1rem;
  text-transform: uppercase;
}

@media screen and (min-width: 600px) {
  .challenges {
    padding: 1rem 3rem;
  }
}

@media screen and (min-width: 768px) {
  .challenges {
    padding: 1rem 10rem;
  }

  .challenges__container {
    max-width: 64rem;
  }
}

.coil-connect__connected {
  background-color: #2d333a;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  width: 13rem;
  padding: 0.7em 1.2em;
  border-radius: 5px;
  margin-top: 1.2rem;
  margin-bottom: 2rem;
}

.coil-connect__header {
  display: flex;
}

.coil-connect__header-text {
  display: flex;
  align-items: center;
  color: #028090;
  font-weight: 400;
}

.coil-connect__header-connected {
  position: relative;
  font-size: smaller;
  color: green;
  text-transform: uppercase;
  padding-top: 1px;
}

.coil-connect__header-connected-dot {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: green;
  margin: 0 0.2rem 0 0.7rem;
}

.coil-connect__header-not-connected {
  font-size: smaller;
  color: red;
  text-transform: uppercase;
  padding-top: 1px;
}

.coil-connect__header-not-connected-dot {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: red;
  margin: 0 0.2rem 0 0.7rem;
}

.coil-connect__btns {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.coil-connect__btn {
  background-color: #2c333a !important;
  color: #fff !important;
  text-transform: uppercase;
  text-align: center;
  width: 13rem;
  padding: 0.8em 1.2em !important;
  border-radius: 7px !important;
  margin-top: 1.2rem !important;
  margin-bottom: 2rem !important;
}

.coil-connect__btn:hover {
  background-color: #16191d !important;
}

.coil-connect__btn-disconnect label,
.coil-connect__btn label {
  cursor: pointer;
}

@media (max-width: 450px) {
  .coil-connect__btns {
    flex-direction: column;
    grid-gap: unset;
    gap: unset;
  }

  .coil-connect__btn {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}

@media (max-width: 330px) {
  .coil-connect__btns {
    margin-bottom: 1rem !important;
  }
}

.coil-gated-msg {
  position: absolute !important;
  top: 0;
}

.coil-gated-msg__header {
  color: #028090 !important;
}

#comment-feed {
  width: 100%;
}

.comment-form__container {
  width: 100%;
  padding-bottom: 0.5rem;
}

.comment-form__field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment-form__field img {
  width: 2.76rem;
  margin-right: 0.8rem;
}

.comment-form__text {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  position: relative;
}

.comment-form__text:focus {
  outline: none;
  border-bottom: 2px solid rgba(34, 36, 38, 0.5) !important;
}

.comment-form__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.comment-form__buttons.d-none {
  display: none;
}

.comment-form__submit {
  margin-right: 1rem !important;
}

@media (max-width: 450px) {
  .comment-form__buttons {
    flex-direction: column;
    margin-top: 1.5rem;
  }

  .comment-form__submit {
    margin-bottom: 1rem !important;
  }
}

.comment-item__avatar img {
  border-radius: 50% !important;
}

.comment-item__group {
  display: flex;
}

.comment-item__link {
  color: #028090;
}

.confirm-delete__trigger {
  color: #db2828 !important;
  text-transform: uppercase !important;
  cursor: pointer;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.confirm-delete__trigger:focus,
.confirm-delete__trigger:hover {
  color: #fff !important;
  background-color: #db2828 !important;
}

@media (max-width: 450px) {
  .confirm-delete__trigger {
    margin-top: 1rem;
  }
}

.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 2px;
  border: 2px dashed #eee;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  cursor: pointer;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone--active {
  background-color: #f1e2ff;
  border: 5px solid #2fd7b5;
}

.dropzone__content {
  align-self: center;
}

.dropzone.disabled {
  opacity: 0.6;
}



.follow-portal__trigger {
  cursor: pointer;
  border-radius: 5px;
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
}

.follow-portal__trigger:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.follow-portal__trigger-label {
  padding-left: 3px;
}

.follow-portal__container {
  width: 40%;
  max-width: 400px;
  min-height: 200px;
  max-height: 400px;
  overflow-x: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none;
  position: fixed !important;
  top: 5rem;
  left: 5%;
  z-index: 1000;
  border-radius: 10px !important;
}

.follow-portal__header {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 1100;
  top: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 !important;
  padding: 0 1em 1em !important;
  background-color: #fff !important;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none !important;
}

.follow-portal__header-text {
  font-size: 1.2em;
  font-weight: 500;
}

.follow-portal__close {
  position: absolute;
  right: 0;
  cursor: pointer;
  color: #028090;
}

.follow-portal__content {
  min-height: 200px;
  overflow-y: auto;
}

.follow-portal__content.ui.selection.list>.item {
  cursor: default !important;
}

.follow-item {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;
}

.follow-item__user {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  color: #028090 !important;
}

.follow-item__user-avatar {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.follow-item__user-avatar img {
  width: 32px;
  height: 32px;
}

.follow-item__user-name {
  font-size: 1rem !important;
  font-weight: 500;
}

.follow-item__default {
  text-align: center;
}

.follow-item__button-following {
  width: 100px;
  padding: 0.5em 1.5em !important;
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400 !important;
  color: #028090 !important;
  text-align: center !important;
}

.follow-item__button-following:hover {
  color: #fff !important;
  background-color: #db2828 !important;
}

.follow-item__button-following:hover .hidden.content {
  display: inline-block !important;
}

.follow-item__button-follow,
.follow-item__button-following.ui.animated.button {
  padding: 0.5em 1.5em !important;
}

.follow-item__button-follow {
  width: 100px;
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400 !important;
  color: #028090 !important;
  text-align: center !important;
}

.follow-item__button-follow:hover {
  color: #fff !important;
  background-color: #21ba45 !important;
}

.follow-item__button-follow:hover .hidden.content {
  display: inline-block !important;
}

.follow-item__btn-unfollow {
  background-color: #db2828 !important;
}

.follow-item__btn-follow,
.follow-item__btn-unfollow {
  width: 100px;
  padding: 0.5em 1.5em !important;
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400 !important;
  color: #fff !important;
  text-align: center !important;
}

.follow-item__btn-follow {
  background-color: #21ba45 !important;
}

@media (max-width: 800px) {
  .follow-portal__container {
    width: 400px;
    top: 14rem;
    left: calc(50% - 200px);
  }
}

@media (max-width: 450px) {
  .follow-container {
    padding-left: 0;
    justify-content: center;
  }

  .follow-portal__container {
    width: 100%;
    max-width: none;
    top: 12.2rem;
    left: 0;
  }
}

.footer {
  padding: 3rem 0 2rem;
  background-color: #028090;
}

.footer a {
  color: #fff;
}

.footer__container {
  width: 90%;
  margin: 0 auto;
}

.footer__what-gfam {
  justify-content: center;
  font-size: 1.5rem;
  letter-spacing: 1px;
}

.footer__legal,
.footer__what-gfam {
  display: flex;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.footer__legal {
  justify-content: space-around;
}

.footer__legal a {
  padding: 0;
}

.footer__credits {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.footer__credits a {
  font-size: 0.8rem;
  color: #f5f5f5;
}

.footer__credits-developer {
  margin-right: 1.8rem;
}

@media (max-width: 600px) {
  .footer {
    font-size: 0.9em;
    padding: 1rem 0;
  }

  .footer__legal,
  .footer__what-gfam {
    margin-bottom: 1rem;
  }
}

.gated__photo {
  -webkit-filter: blur(4rem);
  filter: blur(4rem);
  position: relative;
}

.gated__story {
  -webkit-filter: blur(0.4rem);
  filter: blur(0.4rem);
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.grant {
  width: 100%;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem 0;
}

.grant__sec-1 {
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.grant__sec-2 {
  flex: 1 1 200px;
  display: grid;
  place-items: center;
}

.grant__text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5em;
  color: #9b9c3e;
  padding-left: 1rem;
}

.grant__logo {
  width: auto;
  padding: 0;
}

.grant__image {
  height: 100%;
}

@media (max-width: 1000px) {
  .grant__text {
    font-size: 1.3rem;
    line-height: 1.4em;
  }
}

@media (max-width: 799px) {
  .grant__text {
    font-size: 1.7rem;
    line-height: 1.5em;
  }
}

@media (max-width: 600px) {
  .grant__text {
    font-size: 1.4rem;
    line-height: 1.4em;
  }
}

@media (max-width: 450px) {
  .grant__text {
    font-size: 1.2rem;
    line-height: 1.3em;
  }
}

.header__container {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  z-index: 900;
  top: 0;
  color: #028090;
  background-color: #e5f5f2;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent,
    0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.header__wrapper {
  justify-content: space-between;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 2rem;
}

.header__user,
.header__wrapper {
  display: flex;
  align-items: center;
}

.header__user img {
  width: 2.76rem;
}

.header__name-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.header__displayName {
  font-size: 1.42857rem;
}

.header__username {
  font-size: 1.14286rem;
  color: rgba(0, 0, 0, 0.6);
  padding-top: 2px;
}

.header__section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.header__section i.icon {
  margin: 0 !important;
}

.header__plus-icon {
  color: #028090;
  font-size: 1.5em;
}

.header__plus-icon i.circular.icon {
  transition: box-shadow 0.3s ease-out;
}

.header__plus-icon:hover i.circular.icon {
  color: #028090;
  box-shadow: inset 0 0 0 0.1em #028090 !important;
}

.header__actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.header__actions-icon {
  margin-left: 0.5rem !important;
}

.header__menu {
  position: relative;
}

.header__menu-icon {
  font-size: 1.5em !important;
  transition: box-shadow 0.3s ease-out;
}

.header__menu-icon:focus,
.header__menu-icon:hover {
  cursor: pointer;
  box-shadow: inset 0 0 0 0.1em #028090 !important;
}

.header__menu-component {
  position: absolute;
}

.header__menu-component.ui.menu.vertical {
  top: 5rem;
  right: 5%;
  z-index: 1000;
  color: #fff;
  background-color: #028090;
}

.header__menu-component.ui.menu.vertical a {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.header__menu-component.ui.menu.vertical .item:focus,
.header__menu-component.ui.menu.vertical .item:hover {
  background-color: #03adc2;
}

.header__menu-item {
  display: flex;
}

.header__login-btn.ui.basic.button {
  color: #028090 !important;
  box-shadow: inset 0 0 0 1px #028090;
}

.header__login-btn.ui.basic.button,
.header__register-btn {
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
}

.header__register-btn {
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #028090 !important;
}

.header__login-btn:focus,
.header__login-btn:hover,
.header__register-btn:focus,
.header__register-btn:hover {
  opacity: 0.6;
}

.header__logo {
  cursor: pointer;
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
}

.header__logo:hover {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.header__logo img {
  height: 3rem;
}

@media (max-width: 1100px) {
  .header__container {
    padding: 20px 16px;
  }
}

@media (max-width: 1000px) {
  .header__container {
    padding: 20px 12px;
  }

  .header__name-wrapper {
    padding: 0.5rem 0.7rem 0;
  }

  .header__displayName {
    font-size: 1.42857rem;
  }

  .header__displayName i {
    font-size: 1.28571rem;
  }

  .header__username {
    font-size: 1rem;
    padding-top: 0;
  }

  .header__menu-icon {
    font-size: 1.3em !important;
  }

  .header__plus-icon {
    font-size: 1.3em;
  }
}

@media (max-width: 900px) {
  .header__container {
    padding: 20px 10px;
  }

  .header__displayName {
    font-size: 1.28571rem;
  }

  .header__displayName span {
    position: absolute;
  }

  .header__displayName i {
    font-size: 1.14286rem;
  }

  .header__username {
    font-size: 1rem;
  }

  .header__section {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .header__logo img {
    height: 2.8rem;
  }
}

@media (max-width: 800px) {
  .header__menu-component.ui.menu.vertical {
    top: 5rem;
    right: calc(50% - 7.5rem);
  }
}

@media (max-width: 540px) {
  .header__container {
    height: 6rem;
    padding: 20px 3px;
  }
}

@media (max-width: 450px) {

  .header__name-wrapper,
  .header__wrapper {
    padding: 0 0.5rem;
  }

  .header__displayName {
    font-size: 1.14286rem;
  }

  .header__username {
    font-size: 1rem;
    padding-top: 0;
  }

  .header__plus-icon {
    font-size: 1.3em;
  }

  .header__actions {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  /* .header__menu {
    margin: 0 0.5rem;
  } */

  .header__menu-icon {
    font-size: 1.3em !important;
    margin: 0 !important;
  }

  .header__menu-user .ui.menu.vertical {
    margin-left: -40vw !important;
  }
}

@media (max-width: 390px) {
  .header__logo img {
    height: 2.4rem;
  }
}

@media (max-width: 365px) {
  .header__username {
    font-size: 0.85714rem;
  }

  .header__login-btn.ui.basic.button,
  .header__register-btn {
    padding: 0.5em 0.7em !important;
    font-size: 0.85714rem !important;
  }
}

.header-search {
  margin-right: 0.5em;
}

.header-search .prompt {
  border-radius: 0.28571429rem !important;
  padding: 0.5em !important;
}

.honest {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  background-color: #8da7b2;
  min-height: 25rem;
}

.honest__header {
  font-size: 2rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.8em;
  text-align: center;
  padding: 4rem 1.5rem 1rem;
  margin: 0;
}

.honest__header--center {
  margin-top: auto !important;
  margin-bottom: auto;
}

.honest__header blockquote:before {
  position: absolute;
  font-family: Georgia, serif;
  font-size: 4em;
  line-height: 1;
  left: -1rem;
  top: -3rem;
  content: '\201C';
}

.honest__header blockquote,
.honest__header blockquote span {
  position: relative;
}

.honest__header blockquote span:after {
  position: absolute;
  font-family: Georgia, serif;
  font-size: 4em;
  line-height: 1;
  right: -4rem;
  bottom: -0.8em;
  content: '\201D';
}

@media screen and (min-width: 1100px) {
  .honest__header blockquote:before {
    left: 3rem;
    top: -2rem;
  }

  .honest__header blockquote span:after {
    right: -3rem;
    bottom: -0.8em;
  }
}


.honest__cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem 2rem;
}

.honest__button {
  color: #fff !important;
  background-color: #028090 !important;
  padding: 0.6rem 3rem 0.7rem !important;
  font-size: 1.5rem !important;
  font-weight: 300;
}

.honest__button:hover {
  background-color: #01535e !important;
}

.honest__button a {
  width: 80%;
  text-transform: uppercase !important;
}

.how-it-works {
  color: #fff;
  background-color: #9b9c3e;
  min-height: 25rem;
}

.how-it-works__header {
  font-size: 2.28571rem;
  font-weight: 500;
  line-height: 1.4em;
  text-align: center;
  padding: 2rem 0;
  margin: 0;
}

.how-it-works__steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-height: 15rem;
}

.how-it-works__step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.how-it-works__step:before {
  content: attr(data-step);
  font-size: 2.28571rem;
  padding: 3px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 4px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  color: #9b9c3e;
  background-color: #fff;
}

.how-it-works__step-text {
  max-width: 15rem;
  padding: 0.5rem;
  text-align: center;
}

.how-it-works__step-text:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
}

.how-it-works__step-icon {
  height: 7rem;
  padding-top: 1rem;
}

.how-it-works__step-icon-coins {
  height: 7rem;
  padding-top: 0.5rem;
}

.how-it-works__step-icons {
  height: 7rem;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.how-it-works__step-icons img {
  width: 32px !important;
  height: 36px !important;
}

.how-it-works__step-title {
  font-size: 1.71429rem;
  font-weight: 500;
  line-height: 1.4em;
  text-align: center;
}

.how-it-works__cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem 2rem;
}

.how-it-works__cta-header {
  font-size: 1.71429rem;
  font-weight: 500;
  line-height: 1.4em;
  text-align: center;
  padding: 1rem 0;
  margin: 0;
}

.how-it-works__button {
  background-color: #fff !important;
  color: #028090 !important;
  padding: 0.6rem 3rem 0.7rem !important;
  font-size: 1.5rem !important;
  font-weight: 300;
}

.how-it-works__button:hover {
  background-color: #e6e6e6 !important;
}

.how-it-works__button a {
  width: 80%;
  text-transform: uppercase !important;
}

@media (max-width: 600px) {
  .how-it-works__header {
    font-size: 1.8rem;
    padding: 1rem 0 1.5rem;
  }

  .how-it-works__step:before {
    font-size: 2rem;
    padding: 3px;
    top: 0;
    left: 2px;
    width: 36px;
    height: 36px;
  }

  .how-it-works__cta-header {
    font-size: 1.42857rem;
    line-height: 1.4em;
    padding: 1rem 0;
    margin: 0;
  }
}

@media (min-width: 601px) and (max-width: 800px) {
  .how-it-works__step:before {
    font-size: 2.28571rem;
    padding: 3px;
    top: 0;
    left: 24px;
    width: 42px;
    height: 42px;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .how-it-works__header {
    font-size: 1.8rem;
    padding: 1rem 0 1.5rem;
  }

  .how-it-works__step:before {
    font-size: 2rem;
    padding: 3px;
    top: 0;
    left: 4px;
    width: 36px;
    height: 36px;
  }

  .how-it-works__cta-header {
    font-size: 1.42857rem;
    line-height: 1.4em;
    padding: 1rem 0;
    margin: 0;
  }
}

@media (min-width: 900px) and (max-width: 999px) {
  .how-it-works__step:before {
    left: 0;
    width: 36px;
    height: 36px;
  }
}

@media (min-width: 1000px) {
  .how-it-works__step:before {
    left: 5%;
  }
}

.intro {
  padding-bottom: 3rem;
  background-color: #007f8e;
}

.intro__container {
  width: 90%;
  margin: 0 auto;
}

.intro__logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro__logo {
  width: 60%;
}

.intro__subheader {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.71429rem;
  text-align: center;
  color: #fff;
}

.intro__headline {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 1.2px;
  text-align: center;
  color: #fff;
  padding-bottom: 2rem;
}

.intro__headline-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro__headline-icon {
  min-width: 0.3em;
  height: 0.3em;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 0.3em;
}

.intro__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
}

.intro__buttons a {
  width: 80%;
  text-transform: uppercase !important;
}

.intro__buttons a+a {
  margin-top: 2rem !important;
}

.intro__button-1 {
  background-color: #fff !important;
  color: #028090 !important;
  font-weight: 700;
}

.intro__button-1:hover {
  background-color: #e6e6e6 !important;
}

@media (max-width: 1000px) {
  .intro__headline {
    font-size: 2.5rem;
  }
}

@media (max-width: 800px) {
  .intro__container {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .intro__subheader {
    font-size: 1.2rem;
  }

  .intro__item-text {
    font-size: 1.4rem;
  }

  .intro__item-title {
    font-size: 2rem;
  }
}

@media (max-width: 450px) {
  .intro__headline {
    font-size: 2rem;
  }
}

.influencers {
  width: 100%;
  padding: 1rem;
  background-color: #748b96;
}

.influencers__top {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.influencers__text-container {
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-right: 2rem;
}

.influencers__gallery {
  --width: 36vw;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: var(--width);
  height: var(--width);
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}

.influencers__gallery-square {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.influencers__text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5em;
  color: #fff;
  padding-left: 1rem;
}

.influencers__earned {
  color: #028090;
  background-color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
}

.influencers__earned+.influencers__earned {
  margin-top: 1rem;
}

.influencers__total {
  font-style: italic;
  animation: loading 0.5s infinite;
}

.influencers__image {
  height: 100%;
}

.influencers__bottom {
  display: grid;
  place-items: center;
  padding: 1.5rem 0 1rem;
}

.influencers__btn-feed {
  color: #028090 !important;
  background-color: #fff !important;
  padding: 0.6rem 3rem 0.7rem !important;
  font-size: 1.5rem !important;
  font-weight: 300;
}

.influencers__btn-feed:focus,
.influencers__btn-feed:hover {
  background-color: #e6e6e6 !important;
}

.influencers__btn-feed a {
  width: 80%;
  text-transform: uppercase !important;
}

@media (max-width: 1000px) {
  .influencers__text {
    font-size: 1.4rem;
    line-height: 1.5em;
  }
}

@media (max-width: 799px) {
  .influencers__text-container {
    padding-right: 0;
  }

  .influencers__text {
    font-size: 1.25rem;
    line-height: 1.5em;

    padding-left: 0;
    padding-bottom: 1rem;
  }

  .influencers__gallery {
    --width: 48vw;
  }
}

@media (max-width: 500px) {
  .influencers__text {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    font-size: 1.1rem;
  }

  .influencers__top {
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .influencers__gallery {
    --width: 60vw;
  }
}

@media (max-width: 330px) {
  .influencers__gallery {
    --width: 80vw;
  }
}

.lazy-image__wrapper {
  width: 12rem;
  height: 12rem;
}

.lazy-image__wrapper,
.lazy-image__wrapper-thumb {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.lazy-image__wrapper-thumb {
  width: 80px;
}

@-webkit-keyframes loading {
  0% {
    background-color: #fff;
  }

  50% {
    background-color: #84bbb0;
  }

  to {
    background-color: #fff;
  }
}

.lazy-image__placeholder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-animation: loading 0.5s infinite;
  animation: loading 0.5s infinite;
}

.lazy-image__img {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.leaderboards {
  height: 100%;
  background-color: #e6f5f2 !important;
}

.leaderboards__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fff;
  background-color: #028090;
  padding: 3rem 0 2rem;
}

.leaderboards__header-text {
  font-size: 1.71429rem;
  font-weight: 300;
  letter-spacing: 0.6rem;
}

.leaderboards__timestamp {
  font-size: 0.85714rem;
  text-transform: uppercase;
}

.leaderboards__timestamp-wrapper {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 300 !important;
}

.leaderboards__tab .ui.menu .item {
  color: rgba(0, 0, 0, 0.6) !important;
  text-transform: uppercase;
}

.leaderboards__tab .ui.secondary.pointing.menu {
  justify-content: space-between !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.leaderboards__tab .ui.secondary.pointing.menu .active.item {
  background-color: initial;
  box-shadow: none;
  border-color: #028090 !important;
  font-weight: 700;
  color: #028090 !important;
}

.leaderboards__pane {
  margin-top: 0 !important;
  padding-top: 0 !important;
  background-color: #e6f5f2 !important;
  box-shadow: none !important;
  border: none !important;
}

@media (max-width: 1200px) {
  .leaderboards__container {
    padding: 0 1rem;
  }

  .leaderboards__tab .ui.secondary.pointing.menu {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .leaderboards__tab .ui.secondary.pointing.menu .item {
    padding: 0.5rem !important;
  }
}

@media (max-width: 1000px) {
  .leaderboards__container {
    padding: 0;
  }

  .leaderboards .ui.secondary.pointing.menu .item {
    padding: 0.3rem !important;
  }
}

@media (max-width: 450px) {
  .leaderboards__header {
    padding: 1.42857rem 0 0.71429rem;
  }

  .leaderboards__tab .ui.secondary.pointing.menu {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .leaderboards__tab .ui.secondary.pointing.menu .item {
    padding: 0.5rem !important;
  }
}

@media (max-width: 330px) {
  .leaderboards__tab .ui.secondary.pointing.menu {
    margin-left: 0.3rem !important;
    margin-right: 0.3rem !important;
  }

  .leaderboards__tab .ui.secondary.pointing.menu .item {
    font-size: 0.85714rem !important;
    padding: 0.3rem !important;
  }
}

.leaderboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaderboard__timestamp {
  font-size: 1rem;
  font-weight: 300 !important;
}

.leaderboard__timestamp span {
  font-size: 0.85714rem;
  font-weight: 300 !important;
}

.leaderboard-list__header {
  color: #028090 !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  margin-top: 1rem !important;
  margin-bottom: 0.4rem !important;
}

.leaderboard-list__container {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(var(--rows), 1fr);
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-column-gap: 3rem;
  -webkit-column-gap: 3rem;
  column-gap: 3rem;
  align-items: center;
  margin: 1em auto !important;
}

@media (max-width: 1100px) {
  .leaderboard-list__container {
    margin: 0 !important;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
}

@media (max-width: 1000px) {
  .leaderboard-list__container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (max-width: 950px) {
  .leaderboard-list__container {
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }
}

@media (max-width: 800px) {
  .leaderboard-list__container {
    grid-auto-flow: column;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
    column-gap: 3rem;
  }
}

@media (max-width: 600px) {
  .leaderboard-list__container {
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
    column-gap: 2rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
  }
}

@media (max-width: 450px) {
  .leaderboard-list__container {
    margin: 0 auto !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.leaderboard-list-item {
  display: flex !important;
  align-items: center;
  padding: 0.21428571em 0;
}

.leaderboard-list-item__rank {
  display: inline-block;
  line-height: 1;
  vertical-align: initial;
  width: 3em;
  height: 2.1666em;
  margin: 0 0.14285714em;
  padding: 0.5833em 0.833em;
  background-color: #e8e8e8;
  background-image: none;
  background-color: none #fff !important;
  color: #767676 !important;
  text-transform: none;
  font-size: 0.85714286rem;
  font-weight: 700;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  transition: background-color 0.1s ease;
  text-align: center;
}

.leaderboard-list-item__rank:first-child {
  margin-left: 0;
}

.leaderboard-list-item__rank.rank--first {
  background-color: #a333c8 !important;
  border-color: #a333c8 !important;
  color: #fff !important;
}

.leaderboard-list-item__rank.rank--second {
  background-color: #2185d0 !important;
  border-color: #2185d0 !important;
  color: #fff !important;
}

.leaderboard-list-item__rank.rank--third {
  background-color: #00b5ad !important;
  border-color: #00b5ad !important;
  color: #fff !important;
}

.leaderboard-list-item__rank.rank--fourth {
  background-color: #21ba45 !important;
  border-color: #21ba45 !important;
  color: #fff !important;
}

.leaderboard-list-item__rank.rank--fifth {
  background-color: #b5cc18 !important;
  border-color: #b5cc18 !important;
  color: #fff !important;
}

.leaderboard-list-item__label {
  cursor: pointer;
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  width: 100% !important;
  min-width: 12rem !important;
  margin-left: 0.4rem !important;
  padding: 0;
  color: #028090 !important;
  background-color: #fff !important;
  text-decoration: none;
  text-transform: none;
  font-size: 0.85714286rem;
  font-weight: 700;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  transition: background 0.1s ease;
}

.leaderboard-list-item__leader {
  display: flex;
  align-items: center;
  width: 100%;
}

.leaderboard-list-item__img {
  display: inline-block;
  vertical-align: top;
  height: 2.1666em;
  border-radius: 50% !important;
}

.leaderboard-list-item__name {
  padding-left: 0.3rem;
}

.leaderboard-list-item__name,
.leaderboard-list-item__score {
  cursor: pointer;
  line-height: 1;
  color: #028090 !important;
  text-transform: none;
  font-size: 0.85714286rem;
  font-weight: 700;
}

.leaderboard-list-item__score {
  display: inline-block;
  padding: 0.5833em 0.833em;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0.8;
}

@media (max-width: 900px) {
  .leaderboard-list-item__label {
    min-width: 9rem !important;
  }
}

@media (max-width: 450px) {
  .leaderboard-list-item__label {
    min-width: 12rem !important;
  }
}

.lesson-content__title-header {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 !important;
  color: #028090 !important;
}

.lesson-content__photo {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 700/467;
}

.lesson-content__photo-container {
  overflow: hidden;
}

.lesson-content__info {
  margin-top: 0.5rem;
  padding: 1rem;
  color: #fff !important;
  background-color: #028090 !important;
  border-radius: 3px;
}

.lesson-content__header {
  display: flex;
  justify-content: space-between;
}

.lesson-content__link {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff !important;
}

.lesson-content__link a:hover {
  color: inherit !important;
}

.lesson-content__avatar {
  width: 2.8rem;
}

.lesson-content__name-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  line-height: normal;
  padding: 0 1rem;
}

.lesson-content__author {
  font-size: 1.125rem;
}

.lesson-content__username {
  font-size: 1rem;
  font-weight: 400;
}

.lesson-content__date {
  font-weight: 400;
  text-transform: uppercase;
}

.lesson-content__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.lesson-content__meta {
  height: 2rem;
}

.lesson-content__menu i.icon {
  font-size: 2em;
  color: #fff;
}

.lesson-content__menu-edit {
  text-transform: uppercase !important;
  text-align: center;
  color: #028090 !important;
  padding: 0.78571429rem 1.14285714rem !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  line-height: 1rem;
}

.lesson-content__menu-edit:focus,
.lesson-content__menu-edit:focus a,
.lesson-content__menu-edit:hover,
.lesson-content__menu-edit:hover a {
  color: #fff !important;
  background-color: #028090 !important;
}

.lesson-content__comment-count,
.lesson-content__tip-count {
  margin-left: 2rem !important;
}

.lesson-content__views-icon {
  margin: 0 0.25rem -0.25rem 0;
  width: 1.3em;
  height: 1.3em;
}

.lesson-content__section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.lesson-content__tipped-by-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 10rem;
}

.lesson-content__actions {
  margin-top: 0.75rem;
  display: flex;
  justify-content: space-between;
}

.lesson-content__actions-group {
  display: flex;
  width: 100%;
}

.lesson-content__tip-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-transform: uppercase !important;
}

.lesson-content__edit-button {
  color: #a8e8f0;
  text-transform: uppercase;
  cursor: pointer;
}

.lesson-content__edit-button:hover {
  color: #028090;
}

.lesson-content__gated-label {
  line-height: unset !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.lesson-content__gated-icon {
  margin-right: 0.5rem;
  width: 1.1rem;
  height: 1.1rem;
  fill: #fff;
}

.lesson-content__gated-icon--primary {
  margin-right: 0.5rem;
  width: 1.1rem;
  height: 1.1rem;
  fill: #028090;
}

.lesson-content__content {
  margin: 1rem 0;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.68);
}

.lesson-content__content-link,
.lesson-content__content a {
  color: #028090;
}

.lesson-content__content iframe {
  margin: 0 auto;
  max-width: 100% !important;
  max-height: 100% !important;
  border: none !important;
}

.lesson-content__content img {
  max-width: 100% !important;
  width: auto;
  height: auto;
}

@media (max-width: 450px) {
  .lesson-content__avatar {
    width: 36px;
  }

  .lesson-content__name-wrapper {
    padding: 0 0.5rem;
  }

  .lesson-content__comment-count,
  .lesson-content__tip-count {
    margin-left: 1rem !important;
  }

  .lesson-content__actions,
  .lesson-content__actions-group {
    flex-direction: column;
  }

  .lesson-content__gated-label {
    align-self: center;
  }

  .lesson-content__tip-button {
    width: 100% !important;
  }

  .lesson-content__edit-button {
    margin-top: 1rem;
  }
}

.lesson__comment-container {
  margin: 2rem 0;
}

.lesson-tip__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  width: 30rem;
}

.lesson-tip__group {
  width: 100%;
}

.lesson-tip__group-title {
  background-color: #eef5f6 !important;
}

.lesson-tip__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  padding: 1rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.lesson-tip__title {
  font-size: 18px;
  font-weight: 600;
  color: #028090;
  margin-bottom: 1rem;
}

.lesson-tip__author {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  color: #028090 !important;
}

.lesson-tip__author-avatar {
  margin-right: 1rem;
}

.lesson-tip__author-name {
  font-size: 1.25rem;
  font-weight: 700;
}

.lesson-tip__author-username {
  font-size: 1rem;
  font-weight: 400;
}

.lesson-tip__date {
  text-align: center;
}

.lesson-tip__tip-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  margin: 2rem 0;
}

.lesson-tip__cancel {
  color: #028090 !important;
  background-color: #fff !important;
  text-transform: uppercase !important;
  border: 1px solid #028090 !important;
}

.lesson-tip__submit {
  margin: 0 !important;
  color: #fff !important;
  background-color: #028090 !important;
}

@media (max-width: 450px) {
  .lesson-tip__container {
    width: unset;
  }
}

.lesson-card__card-container {
  width: 20rem !important;
  height: 28rem !important;
  margin: 0 !important;
  border: none !important;
  box-shadow: none !important;
  -webkit-perspective: 800px;
  perspective: 800px;
}

.lesson-card__image-section {
  height: 18rem;
}

.lesson-card__image-section .ui.corner.label {
  top: 1px !important;
  right: 2px !important;
  border-color: #fff !important;
}

.lesson-card__image-section svg {
  color: #028090 !important;
  margin-top: 0.4rem;
  margin-left: 1.5rem;
}

.lesson-card__username {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.lesson-card__description {
  font-size: 1rem;
  line-height: 1.4285em;
  padding: 0.2rem 0 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}

.lesson-card__stats {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.4rem;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  color: rgba(0, 0, 0, 0.4);
  background-color: #fff;
}

.lesson-card__stats .date {
  text-transform: uppercase;
  text-align: center;
}

.lesson-card__count {
  display: flex;
  align-items: baseline;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.lesson-card__views-icon {
  fill: rgba(0, 0, 0, 0.4);
  margin: 0 0.25rem -0.25rem 0;
  width: 1.2em;
  height: 1.2em;
}

.lesson-card__timestamp {
  text-align: center;
  font-size: 0.9rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.4);
}

.lesson-card__end {
  background-color: initial;
  height: 20px;
}

.lesson-card-back__header {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 !important;
  padding: 1rem !important;
}

.lesson-card-back__title {
  margin-bottom: 1rem !important;
  color: #028090 !important;
}

.lesson-card-back__title-wrapper {
  text-align: center;
}

.lesson-card-back__description {
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.8) !important;
}

.lesson-card-back__date {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8) !important;
  text-transform: uppercase;
}

.lesson-card-back__hashtags {
  font-size: 14px;
  text-align: center;
  margin: 0.5rem 0;
  color: #028090 !important;
}

.lesson-card-back__info {
  padding: 1rem;
  color: #fff;
  background-color: #028090;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.lesson-card-back__author {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 0;
}

.lesson-card-back__author-avatar {
  width: 2.8rem;
  margin-right: 1rem;
}

.lesson-card-back__author-name {
  font-size: 1.25rem;
}

.lesson-card-back__author-username {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.lesson-card-back-name-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  line-height: normal;
  padding: 0 1rem;
}

.lesson-card-back__meta {
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  margin: 0.5rem auto;
  font-size: 16px;
}

.lesson-card-back__meta span+span {
  margin-left: 1.5rem;
}

.lesson-card-back__meta i.icon {
  font-size: 16px;
}

.lesson-card-back__views {
  display: flex;
  justify-content: center;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.lesson-card-back__views-icon {
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.25rem;
}

.lesson-card-back__gated {
  display: flex;
  align-items: center;
  margin: 0.5rem auto 0;
  width: -webkit-max-content;
  width: max-content;
  padding: 0.25rem 1rem;
  color: #028090 !important;
  background-color: #fff;
  border-radius: 8px;
}

.lesson-card-back__tip-icon {
  width: 1em;
  height: 1em;
  margin-right: 0.25rem;
}

.lesson-card-back__coil-icon {
  width: 1.4em;
  height: 1.4em;
  margin-right: 0.25rem;
}

.lesson-form {
  margin-top: 1.5rem;
}

.lesson-form__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #028090 !important;
  padding-bottom: 2rem;
}

.lesson-form__input label {
  color: #028090 !important;
  font-weight: 400 !important;
}

.lesson-form__gated {
  width: 80%;
}

.lesson-form__gated-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0.5rem;
}

.lesson-form__gated-toggle {
  display: flex;
  align-items: center;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
}

.lesson-form__gated-label {
  color: #028090 !important;
  font-size: 1rem;
  font-weight: 400 !important;
  text-transform: uppercase;
}

.lesson-form__gated-checkbox {
  padding-top: 0.25rem;
}

.lesson-form__gated-checkbox .ui.toggle.checkbox input:checked~label {
  color: #028090 !important;
}

.lesson-form__gated-checkbox .ui.toggle.checkbox input:checked~label:before {
  background-color: #028090 !important;
}

.lesson-form__gated-checkbox .ui.toggle.checkbox label {
  color: rgba(0, 0, 0, 0.68) !important;
}

.lesson-form__gated-info {
  width: auto;
  text-transform: uppercase;
  text-align: center;
}

.lesson-form__gated-info a {
  color: #028090 !important;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out !important;
}

.lesson-form__gated-info a:focus,
.lesson-form__gated-info a:hover {
  color: #007f8e !important;
  font-weight: bolder;
  text-decoration: underline !important;
}

.lesson-form__btns {
  width: 80%;
  margin-top: 1rem;
}

.lesson-form__btns button {
  margin-bottom: 1rem !important;
}

.lesson-form__btn-submit {
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #028090 !important;
  text-transform: uppercase !important;
  opacity: 0.8;
}

.lesson-form__btn-submit:focus,
.lesson-form__btn-submit:hover {
  opacity: 1;
}

.lesson-form__btn-cancel {
  text-transform: uppercase !important;
}

.lesson-form__btn-cancel:focus,
.lesson-form__btn-cancel:hover {
  color: #fff !important;
  background-color: #028090 !important;
}

.lesson-form__btn-cancel.ui.basic.button {
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  color: #028090 !important;
  box-shadow: inset 0 0 0 1px #028090;
  margin-bottom: 1rem;
}

.lesson-form__div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  margin-bottom: 2em;
}

.lesson-form__heading {
  margin: 0 auto;
  text-align: center;
  color: #028090 !important;
}

.lesson-form__heading--text {
  text-align: center;
  padding: 0 4rem;
  font-size: 1.78571rem;
  line-height: 29px;
  color: #707070;
}

.lesson-form__div--form-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.lesson-form__div--input-container {
  width: 80%;
  padding: 0.5rem 0;
}

.lesson-form__div--input-container .ui.header {
  color: #028090 !important;
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400;
}

.lesson-form__div--input-container .tox-tinymce {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.lesson-form__group {
  margin-top: 2rem;
}

.lesson-form__div--spacer {
  width: 100%;
  height: 1px;
  background: transparent;
  margin: 16px 0;
}

.lesson-form__links {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  width: 80%;
}

.lesson-form__guidelines {
  width: auto;
  text-transform: uppercase;
  text-align: center;
}

.lesson-form__guidelines a {
  color: #028090 !important;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out !important;
}

.lesson-form__guidelines a:focus,
.lesson-form__guidelines a:hover {
  color: #007f8e !important;
  font-weight: bolder;
  text-decoration: underline !important;
}

.lesson-form__edit-photo {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  font-style: italic;
  border-radius: 0.28571429rem;
  box-shadow: inset 0 0 0 0 transparent;
  transition: color 0.1s ease, border-color 0.1s ease;
}

@media screen and (max-width: 900px) {
  .lesson-form {
    margin-top: 1rem;
  }
}

@media (max-width: 500px) {
  .lesson-form__heading {
    font-size: 1.57143rem;
    margin: 0 auto;
  }

  .lesson-form__gated-info,
  .lesson-form__guidelines {
    padding: 0;
  }

  .lesson-form__btns,
  .lesson-form__div--input-container,
  .lesson-form__gated,
  .lesson-form__links {
    width: 95%;
  }

  .lesson-form__div--input-container {
    padding: 0.5rem 0;
  }

  .lesson-form__gated {
    margin: 0.5rem 0 !important;
  }

  .lesson-form__gated-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .lesson-form__btns {
    margin-top: 0.5rem;
  }
}

[class*='lesson-form__input']>label {
  color: #028090 !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

.milestone {
  --baseWidth: 50px;
  height: 70px;
  /* overflow: hidden; */
}

.milestone__container {
  position: relative;
  width: var(--baseWidth);
  height: 45px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #2dd4bf;
  background-color: teal;
}

.milestone__container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 20;
}

.milestone__container::after {
  content: '';
  position: absolute;
  bottom: -24px;
  left: 0;
  width: 0;
  height: 0;
  border-color: transparent teal;
  border-width: 0 25px 25px 25px;
}

.milestone__top {
  padding: 2px;
  position: relative;
  height: 24px;
}

.milestone__top::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 2px;
  width: var(--baseWidth);
  height: 1px;
  background-color: #2dd4bf;
}

.milestone__icon {
  width: 20px;
  height: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.milestone__icon-sack {
  width: 18px;
  height: 18px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.milestone__number {
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 18px;
}

.mission {
  background-color: #e6f5f2;
}

.mission__container {
  width: 90%;
  margin: 0 auto;
  padding: 3rem 0 4rem;
}

.mission__text {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.mission__text span {
  padding-left: 0.5rem;
}

.password-reset__heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
}

.password-reset__heading h1 {
  margin: 1rem auto;
  color: #028090;
}

.password-reset__info {
  margin-bottom: 1rem !important;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bolder;
}

.password-reset__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.password-reset__form {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #028090;
}

.password-reset__input-container {
  width: 100%;
  padding: 0.5rem 1rem;
}

.password-reset__btn {
  margin: 1rem !important;
  min-width: 100px;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
}

@media (max-width: 600px) {
  .password-reset__info {
    margin: 0 0 0.5rem !important;
  }

  .password-reset__btn {
    margin: 0.5rem 1rem 0 !important;
  }
}

@media (max-width: 700px) {

  .password-reset__form,
  .password-reset__heading {
    width: 80%;
  }
}

[class*='password-reset__input']>label {
  color: #028090 !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

.pwd-upd {
  margin-bottom: 2rem;
}

.pwd-upd__trigger {
  color: #028090 !important;
  margin-left: 1rem !important;
}

.pwd-upd__input {
  margin: 0.8rem 0 0.3rem;
}

.pwd-upd__input label {
  text-transform: uppercase;
  color: #028090;
}

.pwd-upd__header {
  padding: 1.25rem 1.5rem;
  margin: 0;
  color: #028090 !important;
  border-bottom: 1px solid rgba(34, 36, 38, .15);
}

.pwd-upd__actions {
  background-color: unset !important;
  border: unset !important;
}

.pwd-upd__btn {
  text-transform: uppercase !important;
  font-weight: 400 !important;
}

[class*='pwd-upd__show']>label {
  color: #028090 !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

[class*='pwd-upd__show'].ui.checkbox input:checked~.box:after,
[class*='pwd-upd__show'].ui.checkbox input:checked~label:after {
  color: #028090 !important;
}

@media (max-width: 450px) {
  .pwd-upd {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .pwd-upd__trigger {
    width: 100% !important;
    margin-top: 1rem !important;
    margin-left: 0 !important;
  }

  .pwd-upd__input label {
    font-size: smaller;
  }
}

.post__container,
.post__page {
  position: relative;
}

.post__container {
  height: 100%;
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .post__container {
    width: 95%;
  }

  .post__page {
    margin-top: 0;
  }
}

.post-content {
  margin-top: 2em !important;
}

.post-content__photo {
  width: 100%;
  object-fit: cover;
}

.post-content__photo-container {
  overflow: hidden;
}

.post-content__body {
  padding: 0.5rem 1rem 1rem !important;
  background-color: #028090 !important;
}

.post-content__header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: #fff !important;
  font-size: 1.78571rem !important;
}

.post-content__header a {
  color: #fff !important;
}

.post-content__link {
  display: flex;
  align-items: center;
}

.post-content__avatar {
  width: 2.8rem;
}

.post-content__name-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  line-height: normal;
  padding: 0 1rem;
}

.post-content__author {
  font-size: 1.57143rem;
}

.post-content__username {
  font-size: 1.28571rem;
  font-weight: 400;
  color: hsla(0, 0%, 100%, 0.6);
}

.post-content__timestamp {
  margin-top: 0.5rem;
  font-size: 0.85714rem;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}

.post-content__description {
  color: #fff !important;
}

.post-content__story {
  word-wrap: break-word;
}

.post-content__story .tw-data-text {
  white-space: break-spaces;
}

.post-content__story-link,
.post-content__story a {
  color: #028090;
}

.post-content__comment-count,
.post-content__story,
.post-content__tip-count,
.post-content__views-count {
  color: rgba(0, 0, 0, 0.68);
}

.post-content__comment-count,
.post-content__tip-count {
  margin-left: 2rem !important;
}

.post-content__views-icon {
  fill: rgba(0, 0, 0, 0.68);
  margin: 0 0.25rem -0.25rem 0;
  width: 1.3em;
  height: 1.3em;
}

.post-content__tip-total {
  color: #028090;
  margin-top: 1rem !important;
}

.post-content__tipped-by-item {
  min-width: 10rem;
}

.post-content__tipped-by-item,
.post-content__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post-content__actions {
  display: flex;
  justify-content: space-between;
}

.post-content__actions-group {
  display: flex;
  width: 100%;
}

.post-content__tip-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 120px !important;
  text-transform: uppercase !important;
}

.post-content__edit-button {
  color: #a8e8f0;
  text-transform: uppercase;
  cursor: pointer;
}

.post-content__edit-button:hover {
  color: #028090;
}

.post-content__gated-label {
  align-self: center;
  display: inline-flex !important;
  line-height: unset !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.post-content__gated-icon {
  margin-right: 0.5rem;
  width: 1.1rem;
  height: 1.1rem;
  fill: #fff;
}

.post-content__gated-icon--primary {
  margin-right: 0.5rem;
  width: 1.1rem;
  height: 1.1rem;
  fill: #028090;
}

.post-content__story iframe {
  margin: 0 auto;
  max-width: 100% !important;
  max-height: 100% !important;
  border: none !important;
}

.post-content__story img {
  max-width: 100% !important;
  height: auto !important;
}

@media (max-width: 450px) {
  .post-content__body {
    padding-top: 0.3rem !important;
  }

  .post-content__avatar {
    width: 36px;
  }

  .post-content__name-wrapper {
    padding: 0 0.5rem;
  }

  .post-content__author {
    font-size: 1.57143rem;
  }

  .post-content__comment-count,
  .post-content__tip-count {
    margin-left: 1rem !important;
  }

  .post-content__actions,
  .post-content__actions-group {
    flex-direction: column;
  }

  .post-content__tip-button {
    width: 100% !important;
  }

  .post-content__edit-button {
    margin-top: 1rem;
  }
}

.post-feed {
  margin: 0 auto 2rem;
}

.post-feed__end {
  background-color: initial;
  height: 20px;
}

.post-form {
  margin-top: 1.5rem;
}

.post-form__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #028090 !important;
  padding-bottom: 2rem;
}

.post-form__input label {
  color: #028090 !important;
  font-weight: 400 !important;
}

.post-form__gated {
  width: 80%;
}

.post-form__gated-toggle {
  display: flex;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
  padding: 1rem 0 0.5rem 1rem;
}

.post-form__gated-label {
  color: #028090 !important;
  font-size: 1rem;
  font-weight: 400 !important;
}

.post-form__gated-checkbox .ui.toggle.checkbox input:checked~label {
  color: #028090 !important;
}

.post-form__gated-checkbox .ui.toggle.checkbox input:checked~label:before {
  background-color: #028090 !important;
}

.post-form__gated-checkbox .ui.toggle.checkbox label {
  color: rgba(0, 0, 0, 0.68) !important;
}

.post-form__btns {
  width: 80%;
  margin-top: 1rem;
}

.post-form__btns button {
  margin-bottom: 1rem !important;
}

.post-form__btn-submit {
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #028090 !important;
  text-transform: uppercase !important;
  opacity: 0.8;
}

.post-form__btn-submit:focus,
.post-form__btn-submit:hover {
  opacity: 1;
}

.post-form__btn-cancel {
  text-transform: uppercase !important;
}

.post-form__btn-cancel:focus,
.post-form__btn-cancel:hover {
  color: #fff !important;
  background-color: #028090 !important;
}

.post-form__btn-cancel.ui.basic.button {
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  color: #028090 !important;
  box-shadow: inset 0 0 0 1px #028090;
  margin-bottom: 1rem;
}

.post-form__div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  margin-bottom: 2em;
}

.post-form__heading {
  margin: 0 auto;
  text-align: center;
  color: #028090 !important;
}

.post-form__heading--text {
  text-align: center;
  padding: 0 4rem;
  font-size: 1.78571rem;
  line-height: 29px;
  color: #707070;
}

.post-form__div--form-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.post-form__div--input-container {
  width: 80%;
  padding: 0.5rem 0;
}

.post-form__div--input-container .ui.header {
  color: #028090 !important;
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400;
}

.post-form__div--input-container .tox-tinymce {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.post-form__group {
  margin-top: 2rem;
}

.post-form__div--spacer {
  width: 100%;
  height: 1px;
  background-color: initial;
  margin: 16px 0;
}

.post-form__links {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  width: 80%;
}

.post-form__gated-info,
.post-form__guidelines {
  width: auto;
  text-transform: uppercase;
  text-align: center;
}

.post-form__gated-info a,
.post-form__guidelines a {
  color: #028090 !important;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out !important;
}

.post-form__gated-info a:focus,
.post-form__gated-info a:hover,
.post-form__guidelines a:focus,
.post-form__guidelines a:hover {
  color: #007f8e !important;
  font-weight: bolder;
  text-decoration: underline !important;
}

.post-form__edit-photo {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  font-style: italic;
  border-radius: 0.28571429rem;
  box-shadow: inset 0 0 0 0 transparent;
  transition: color 0.1s ease, border-color 0.1s ease;
}

@media screen and (max-width: 900px) {
  .post-form {
    margin-top: 1rem;
  }
}

@media (max-width: 500px) {
  .post-form__heading {
    font-size: 1.57143rem;
    margin: 0 auto;
  }

  .post-form__gated-info,
  .post-form__guidelines {
    padding: 0;
  }

  .post-form__btns,
  .post-form__div--input-container,
  .post-form__gated,
  .post-form__links {
    width: 95%;
  }

  .post-form__div--input-container {
    padding: 0.5rem 0;
  }

  .post-form__gated {
    margin: 0.5rem 0 !important;
  }

  .post-form__btns {
    margin-top: 0.5rem;
  }
}

[class*='post-form__input']>label {
  color: #028090 !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

.post-form-gated {
  width: 80%;
  padding: 16px;
}

.post-form-gated .ui.header {
  color: #028090 !important;
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400;
}

.post-form-gated__intro p {
  color: rgba(0, 0, 0, 0.68);
}

.post-form-gated__controls {
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
}

.post-form-gated__controls p {
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.68);
}

.post-form-gated__label {
  color: #028090 !important;
  font-size: 1.14286rem;
  font-weight: 400 !important;
}

.post-form-gated__checkbox .ui.toggle.checkbox input:checked~label {
  color: #028090 !important;
}

.post-form-gated__checkbox .ui.toggle.checkbox input:checked~label:before {
  background-color: #028090 !important;
}

.post-form-gated__checkbox .ui.toggle.checkbox label {
  color: rgba(0, 0, 0, 0.68) !important;
}

.post-form-gated__enable,
.post-form-gated__fee {
  display: flex;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
  column-gap: 1rem;
}

.post-form-gated__fee {
  align-items: baseline;
}

.post-form-gated__fee--label {
  color: #028090 !important;
  font-size: 1rem;
  font-weight: 400 !important;
}

.post-form-gated__fee--control {
  display: flex;
}

.post-form-gated__fee--control i {
  color: #fff;
  opacity: 1 !important;
}

.post-form-gated__fee--input {
  margin-right: 3px;
  height: 36px;
}

.post-form-gated__fee--input input {
  font-weight: 700 !important;
  width: 3.5rem !important;
  color: #028090 !important;
  text-align: center !important;
  border: 1px solid #028090 !important;
}

.post-form-gated__fee--input .ui.label {
  font-weight: 400;
  color: #fff !important;
  background-color: #028090 !important;
  line-height: revert;
}

.post-form-gated__fee--input input::-webkit-inner-spin-button,
.post-form-gated__fee--input input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.post-form-gated__fee--input input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.post-group {
  margin-bottom: 3rem;
}

.post-group__heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.post-group__heading .ui.header {
  margin-bottom: 0 !important;
}

.post-group__header {
  margin-left: 1.5rem;
  color: #028090;
  font-weight: 400;
  font-size: 1.71429rem;
  line-height: 35px;
}


.post-group__btn {
  display: flex;
  justify-content: center;
}

.post-group__btn .ui.basic.button {
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  color: #028090 !important;
  box-shadow: inset 0 0 0 1px #028090;
}

.post-group__btn .ui.basic.button:focus,
.post-group__btn .ui.basic.button:hover {
  color: #fff !important;
  background-color: #028090 !important;
}

.post-group__sort {
  margin-right: 1rem;
  color: rgb(0 0 0 / 0.6)
}

.post-group__sort .ui.selection.dropdown {
  min-width: 120px;
  min-height: 2em !important;
  padding: 0.3rem 0.3rem 0.3rem 0.5rem;
  line-height: 1.4285em;
}

.post-group__sort .ui.selection.dropdown i.icon {
  float: right;
}

.post-group__sort i {
  color: #c4c0c0;
  margin-left: 2px;
}

@media (max-width: 800px) {
  .post-group {
    margin-bottom: 1rem;
  }
}

@media (max-width: 450px) {
  .post-feed {
    margin-top: 0;
  }

  .post-group__header {
    margin-left: 1rem;
    font-size: 1.42857em;
    line-height: 28px;
  }

  .post-group__sort {
    padding-left: 1.5rem;
    margin-right: 0;
  }

  .post-group .ui.items {
    margin: 10px 0;
  }
}

.post-item {
  padding-left: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.post-item__rank {
  width: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #028090;
}

.post-item__link {
  margin-right: 1.5em;
  cursor: pointer;
}

.post-item__link-author {
  cursor: pointer;
}

.post-item__link.gated__photo-wrapper img {
  -webkit-filter: blur(1rem);
  filter: blur(1rem);
}

.post-item__header {
  display: flex;
  align-items: baseline;
  grid-gap: 1rem;
  gap: 1rem;
}

.post-item__header-author {
  color: #028090 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.28571rem;
}

.post-item-username {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.14286rem;
  padding-left: 1ch;
}

.post-item__comment-count,
.post-item__description {
  margin-top: 0.2em !important;
}

.post-item__upvote-count,
.post-item__views-count {
  margin-right: 1rem !important;
}

.post-item__views-icon {
  fill: rgba(0, 0, 0, 0.4);
  margin: 0 0.25rem -0.25rem 0;
  width: 1.3em;
  height: 1.3em;
}

.post-item__tips {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 8px;
}

.post-item__gated-icon {
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  fill: #028090;
}

.post-item__logo-xrp {
  width: 28px;
  height: 28px;
  padding: 2px;
  border: 1px solid #028090;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-item__tips-amount {
  font-size: 1.28571rem;
  line-height: 15px;
  color: #028090;
  padding-left: 0.4rem;
}

@media (max-width: 450px) {
  .post-item {
    position: relative;
    padding: 10px 0 !important;
  }

  .post-item__header-author {
    font-size: 1.14286rem;
  }

  .post-item__description {
    font-size: 1rem;
  }

  .post-item__gated-icon {
    position: absolute;
    right: 1.2rem;
    bottom: 0.5rem;
    margin-right: unset;
  }

  .post-item__gated-icon,
  .post-item__logo-xrp {
    width: 1.42857rem;
    height: 1.42857rem;
  }

  .post-item__tips {
    margin-right: 0.25rem;
  }

  .post-item__tips-amount {
    font-size: 1rem;
  }
}

@media (max-width: 330px) {
  .post-item__link {
    margin-right: 0.6em;
  }
}

.post-menu {
  position: relative;
}

.post-menu i.icon {
  font-size: 1.5em !important;
  border-radius: 500em !important;
  line-height: 1 !important;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.3s ease-out;
}

.post-menu i.icon:focus,
.post-menu i.icon:hover {
  cursor: pointer;
  color: #028090 !important;
}

.post-menu__edit {
  text-transform: uppercase !important;
  text-align: center;
  color: #028090 !important;
  padding: 0.78571429rem 1.14285714rem !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  line-height: 1rem;
}

.post-menu__edit:focus,
.post-menu__edit:focus a,
.post-menu__edit:hover,
.post-menu__edit:hover a {
  color: #fff !important;
  background-color: #028090 !important;
}

.post-nav {
  --size: 6rem;
  position: fixed;
  top: 25vh;
  left: 0;
  right: 2rem;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--size);
}

.post-nav:focus .post-nav__btn,
.post-nav:hover .post-nav__btn {
  opacity: 1;
  background-color: rgba(2, 128, 144, 0.2) !important;
}

.post-nav__btn {
  color: #028090 !important;
  background-color: initial !important;
  opacity: 0.1;
  font-size: 2.5rem !important;
  padding: 0.3em !important;
  margin: 0 !important;
}

@media (max-width: 800px) {
  .post-nav {
    --iconPaddingOffset: 0.5rem;
    width: calc(100% + var(--iconPaddingOffset));
    left: calc(var(--iconPaddingOffset) * -1);
  }

  .post-nav:focus .post-nav__btn,
  .post-nav:hover .post-nav__btn {
    opacity: 1;
    background-color: initial !important;
  }

  .post-nav__btn {
    padding: 0 !important;
  }
}

.post-preview {
  margin-top: 2em !important;
}

.post-preview__photo {
  object-fit: cover;
}

.post-preview__body {
  background-color: #028090 !important;
}

.post-preview__header {
  margin-bottom: 1rem;
  color: #fff !important;
  font-size: 1.78571rem !important;
}

.post-preview__username {
  margin-left: 1rem;
}

.post-preview__description {
  color: #fff !important;
}

.post-preview__comment-count,
.post-preview__story,
.post-preview__tip-count,
.post-preview__tip-total {
  color: rgba(0, 0, 0, 0.68);
}

.post-preview__comment-count,
.post-preview__tip-count,
.post-preview__tip-total {
  margin-left: 2em !important;
}

.post-preview__tip-total {
  float: right;
}

.post-preview__modal-close {
  margin-left: 0 !important;
}

@media (max-width: 800px) {
  .post-preview {
    margin: 2em 0 !important;
  }
}

.post-photo__container {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.post-photo__img {
  width: 100%;
  object-fit: cover;
  user-select: none;
  -webkit-touch-callout: none;
  pointer-events: none;
}

@keyframes loading {
  0% {
    background-color: #fff;
  }

  50% {
    background-color: #84bbb0;
  }

  to {
    background-color: #fff;
  }
}

.post-photo__placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-animation: loading 0.5s infinite;
  animation: loading 0.5s infinite;
}

.post-tip__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 1rem auto;
}

.post-tip__group {
  width: 90%;
}

.post-tip__title {
  background-color: #eef5f6 !important;
}

.post-tip__title-header {
  text-align: center;
}

.post-tip__title-header,
.post-tip__title-subheader {
  color: #028090 !important;
  font-weight: 300 !important;
}

.post-tip__title-subheader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  font-size: 1.28571429rem;
}

.post-tip__item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.post-tip__item-image {
  margin-right: 1.5em;
}

.post-tip__item-image.gated__photo-wrapper img {
  -webkit-filter: blur(1rem);
  filter: blur(1rem);
}

.post-tip__item-content {
  flex-grow: 1;
}

.post-tip__item-author {
  display: flex;
  align-items: baseline;
  grid-gap: 1rem;
  gap: 1rem;
}

.post-tip__item-timestamp {
  margin: 0.5em 0;
  font-size: 1em;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.6);
}

.post-tip__item-header {
  color: #028090 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.42857rem;
  line-height: 20px;
}

.post-tip__item-extra {
  margin: 0.5rem 0 0;
  color: rgba(0, 0, 0, 0.4);
}

.post-tip__item-comment-count,
.post-tip__item-description {
  margin-top: 0.2em !important;
}

.post-tip__item-upvote-count {
  margin-right: 1rem !important;
}

.post-tip__item-tips {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.post-tip__item-logo {
  width: 32px;
  height: 32px;
  padding: 2px;
  border: 1px solid #c4c0c0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-tip__item-tips-amount {
  font-size: 1.14286rem;
  line-height: 15px;
  color: #028090;
  margin-top: 0.5rem;
}

.post-tip__donate {
  display: flex;
}

.post-tip__control {
  background-color: #eef5f6 !important;
}

@media (max-width: 800px) {
  .post-tip__container {
    justify-content: start;
    width: 80%;
  }

  .post-tip__group {
    margin-top: 1rem !important;
  }

  .post-tip__item {
    padding: unset;
  }
}

@media (max-width: 500px) {
  .post-tip__container {
    width: 100%;
  }

  .post-tip__title-header {
    font-size: 1.7rem !important;
  }

  .post-tip__title-subheader {
    font-size: 1rem !important;
  }

  .post-tip__item {
    flex-direction: column;
  }

  .post-tip__item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 0.5rem 0;
  }

  .post-tip__item-header {
    font-size: 1.28571rem;
  }

  .post-tip__item-description,
  .post-tip__item-tips-amount {
    font-size: 1rem;
  }
}

.post-tip-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 0;
}

.post-tip-control__group {
  display: flex;
  justify-content: space-between;
}

.post-tip-control__amount {
  display: flex;
  min-width: 45%;
}

.post-tip-control__amount i {
  color: #fff;
  opacity: 1 !important;
}

.post-tip-control__input {
  margin-right: 3px;
}

.post-tip-control__input input {
  font-weight: 700 !important;
  color: #028090 !important;
  text-align: center !important;
  border: 1px solid #028090 !important;
}

.post-tip-control__input input::-webkit-inner-spin-button,
.post-tip-control__input input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.post-tip-control__input input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.post-tip-control__cancel {
  color: #028090 !important;
  background-color: #fff !important;
  text-transform: uppercase !important;
  border: 1px solid #028090 !important;
  margin-top: 0.75rem !important;
}

.post-tip-control__amount button,
.post-tip-control__submit {
  color: #fff !important;
  background-color: #028090 !important;
}

.post-tip-control__amount button i,
.post-tip-control__submit i {
  color: #fff;
  opacity: 1 !important;
}

.post-tip-control__submit {
  min-width: 45%;
  margin: 0 !important;
}

@media (max-width: 450px) {

  .post-tip-control,
  .post-tip-control__group {
    flex-direction: column;
  }

  .post-tip-control__amount {
    justify-content: center;
  }

  .post-tip-control__submit {
    margin: 0.75rem 0 !important;
  }

  .post-tip-control__cancel {
    margin: 0 !important;
  }
}

.profile {
  width: 100%;
  height: 100%;
}

.profile,
.profile-posts {
  background-color: #e6f5f2;
}

.profile-posts {
  padding-top: 2rem;
}

.profile-posts__container {
  margin: 2rem auto;
}

.profile-posts__header {
  text-align: center;
}

.profile-posts__section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.profile-posts__card-container {
  width: 12rem !important;
  height: 12rem !important;
  margin: 0 !important;
  border: none !important;
  box-shadow: none !important;
  background-color: #e6f5f2 !important;
  -webkit-perspective: 800px;
  perspective: 800px;
}

.profile-posts__card-container:hover .profile-posts__card {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.profile-posts__card {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.profile-posts__back,
.profile-posts__front {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e6f5f2;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
}

.profile-posts__front {
  z-index: 2;
  width: 12rem !important;
  height: 12rem !important;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  border-radius: 5px;
}

.profile-posts__image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 5px;
  transform-style: preserve-3d;
}

.profile-posts__back,
.profile-posts__image {
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.profile-posts__back {
  background-color: #e6f5f2;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.profile-posts__back,
.profile-posts__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-posts__content {
  flex-direction: column;
  width: inherit;
  height: inherit;
  background-color: #028090;
  border: 1px solid #028090;
  border-radius: 5px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.profile-posts__author {
  display: flex;
  align-items: center;
  padding: 0.7rem 0;
}

.profile-posts__author-text {
  color: #fff !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
}

.profile-posts__description {
  font-size: 1rem;
  line-height: 1.4285em;
  min-height: 60%;
  padding: 0.2rem 0 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}

.profile-posts__stats {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.4rem;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  color: rgba(0, 0, 0, 0.4);
  background-color: #fff;
}

.profile-posts__count {
  display: flex;
  align-items: baseline;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.profile-posts__views-icon {
  fill: rgba(0, 0, 0, 0.4);
  margin: 0 0.25rem -0.25rem 0;
  width: 1.2em;
  height: 1.2em;
}

.profile-posts__timestamp {
  text-align: center;
  font-size: 0.9rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.4);
}

.profile-posts__end {
  background-color: initial;
  height: 20px;
}

.profile-info {
  width: 100%;
  color: #fff;
  background-color: #028090;
}

.profile-info-content {
  padding: 1rem 15rem 1.5rem;
}

.profile-info__avatar {
  width: 2.76rem;
  height: 2.76rem;
}

.profile-info__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff !important;
}

.profile-info__user {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.profile-info__user-wrapper {
  display: flex;
  flex-direction: column;
}

.profile-info__user-name {
  display: flex;
  align-items: center;
}

.profile-info__user-display {
  font-size: 2rem !important;
  margin-top: 0;
  font-weight: 400;
  /* letter-spacing: 1.5px; */
}

.profile-info__user-username {
  font-size: 1.3rem;
  font-weight: 200;
  padding-top: 0.3rem;
}

.profile-info__loc-join {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  padding: 0 0 0 4rem;
}

.profile-info__follows {
  display: flex;
  column-gap: 1rem;
  padding: 0 0 0 4rem;
}

.profile-info__social {
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.profile-info__social-link {
  color: #fff !important;
  width: 3rem;
  text-decoration: none;
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
}

.profile-info__social-link:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.profile-info__social-link img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile-info__social-link i.icon {
  font-size: 2.28571rem;
  position: relative;
}

.profile-info__social-link i.icon.instagram:before {
  font-size: 2.28571rem;
  margin-top: 6px;
}

.profile-info__social-coil {
  -webkit-transform: translateY(-0.5rem);
  transform: translateY(-0.5rem);
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
}

.profile-info__social-coil:hover {
  -webkit-transform: translateY(-0.5rem) scale(1.2);
  transform: translateY(-0.5rem) scale(1.2);
}

.profile-info__social-icon-link {
  color: #fff;
}

.profile-info__social-icon-cinnamon {
  width: 3rem;
  height: 2.5rem;
  -webkit-transform: scale(1.2) translateY(-0.3rem);
  transform: scale(1.2) translateY(-0.3rem);
}

.profile-info__social-icon-coil,
.profile-info__social-icon-mgSocial {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile-info__joined span,
.profile-info__location span {
  padding-left: 3px;
}

.profile-info__bio {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 1rem 0 0 4rem;
}

.profile-info__bio-text {
  font-size: 1.1rem;
  font-weight: 400;
}

.profile-info__user-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.25rem;
}

.profile-info__user-link {
  display: flex;
  align-items: center;
  color: #fff;
  padding-bottom: 0.5rem;
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
}

.profile-info__user-link:hover {
  color: #fff;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.profile-info__user-link svg {
  padding-right: 0.5rem;
  fill: #fff;
}

.profile-info__tabs .ui.menu {
  margin-bottom: 0.5rem;
}

.profile-info__tabs .ui.secondary.menu .item {
  color: white;
}

.profile-info__tabs .ui.secondary.menu .active.item {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}

.profile-info__stats {
  padding: 1rem;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 5px;
}

.profile-info__stats-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: baseline;
  grid-gap: 1rem;
  gap: 1rem;
}

.profile-info__stats-icon {
  color: #028090;
}

.profile-info__stats-label {
  padding-left: 0.3rem;
}

.profile-info__comment-total,
.profile-info__lesson-total,
.profile-info__post-total,
.profile-info__tip-amounts,
.profile-info__tip-totals,
.profile-info__views-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.68);
}

.profile-info__tip-items {
  text-align: center;
}

.profile-info__views-icon {
  color: #028090;
}

.profile-info__xrp-icon {
  width: 22px;
  height: 22px;
}

.profile-info__web-mon-icon {
  width: 24px;
  height: 24px;
}

.profile-info__btn-wrapper {
  padding-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  min-height: 49px;
}

.profile-info__button-following {
  width: 100px;
  padding: 0.5em 1.5em !important;
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400 !important;
  color: #028090 !important;
  text-align: center !important;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.profile-info__button-following:hover {
  color: #fff !important;
  background-color: #db2828 !important;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.profile-info__button-following:hover .hidden.content {
  display: block !important;
}

.profile-info__button-following.ui.animated.button {
  padding: 0.5em 1.5em !important;
}

.profile-info__button-follow {
  width: 100px;
  padding: 0.5em 1.5em !important;
  font-family: 'Roboto', 'Nunito', 'Open Sans Condensed', sans-serif !important;
  font-weight: 400 !important;
  color: #028090 !important;
  background-color: #fff !important;
  text-align: center !important;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.profile-info__button-follow:hover {
  color: #fff !important;
  background-color: #21ba45 !important;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.profile-info__button-follow:hover .hidden.content {
  display: inline-block !important;
}

@media (max-width: 920px) {
  .profile-info-content {
    padding: 1rem 15% 1.5rem;
  }
}

@media (max-width: 800px) {
  .profile-info {
    position: relative;
    z-index: unset;
  }
}

@media (max-width: 710px) {
  .profile-info-content {
    padding: 1rem 10% 1.5rem;
  }
}

@media (max-width: 620px) {
  .profile-info-content {
    padding: 1rem 5% 1.5rem;
  }
}

@media (max-width: 500px) {
  .profile-info-content {
    padding: 1rem 1rem 1.5rem;
  }

  .profile-info__username-text {
    font-size: 2.1rem !important;
  }

  .profile-info__bio {
    text-align: center;
    padding: 0.25rem 0 0;
  }

  .profile-info__user-links {
    align-items: center;
  }

  .profile-info__stats {
    padding: 1rem 1rem 1rem 1.5rem;
  }

  .profile-info__stats-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: unset;
  }

  .profile-info__comment-total {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  .profile-info__comment-total,
  .profile-info__lesson-total,
  .profile-info__post-total,
  .profile-info__tip-amounts,
  .profile-info__tip-totals,
  .profile-info__views-count {
    flex-direction: row;
    grid-gap: 0.8rem;
    gap: 0.8rem;
  }

  .profile-info__tip-items {
    display: flex;
    flex-direction: column;
    text-align: unset;
  }

  .profile-info__follow-btn {
    padding: 0.6em 1.125em !important;
  }
}

@media (max-width: 450px) {

  .profile-info__follows,
  .profile-info__loc-join {
    padding-left: 0;
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .profile-info-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .profile-info__stats {
    padding: 1rem 1rem 1rem 1.2rem;
  }
}

.profile-post-item {
  padding-left: 0;
  display: flex;
  align-items: center;
}

.profile-post-item__image {
  margin: 0 1.5rem;
  cursor: pointer;
}

.profile-post-item__author {
  cursor: pointer;
}

.profile-post-item__timestamp {
  margin-left: 2rem !important;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  color: rgba(0, 0, 0, 0.4);
}

.profile-post-item__header {
  display: flex;
  align-items: center;
  color: #028090 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.28571rem;
}

.profile-post-item__header-username {
  color: rgba(0, 0, 0, 0.6);
  font-size: 8.28571rem;
  padding-left: 1ch;
}

.profile-post-item__comment-count,
.profile-post-item__description {
  margin-top: 0.2em !important;
}

.profile-post-item__upvote-count,
.profile-post-item__views-count {
  margin-right: 1rem !important;
}

.profile-post-item__views-icon {
  fill: rgba(0, 0, 0, 0.4);
  margin: 0 0.25rem -0.25rem 0;
  width: 1.3em;
  height: 1.3em;
}

.profile-post-item__tips {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-left: 8px;
}

.profile-post-item__logo {
  width: 32px;
  height: 32px;
  padding: 2px;
  border: 1px solid #c4c0c0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-post-item__tips-amount {
  font-size: 1.14286rem;
  line-height: 15px;
  color: #028090;
  margin-top: 0.5rem;
}

@media (max-width: 450px) {
  .profile--post-item__header {
    font-size: 1.28571rem;
  }

  .profile--post-item__description,
  .profile--post-item__tips-amount {
    font-size: 1rem;
  }
}

.reg-form {
  margin-top: 1.5rem;
}

.reg-form__form {
  width: 60%;
  flex-wrap: wrap;
  color: #028090;
}

.reg-form__btns,
.reg-form__form {
  display: flex;
  justify-content: center;
}

.reg-form__btns {
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  width: 80%;
}

.reg-form__btns .button {
  width: 100%;
}

.reg-form__btn1 {
  width: 10rem;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #028090 !important;
  text-transform: uppercase !important;
}

.reg-form__btn1:focus,
.reg-form__btn1:hover {
  opacity: 0.6;
}

.reg-form__btn2 {
  width: 10rem;
  min-width: 100px;
  text-transform: uppercase !important;
}

.reg-form__btn2.ui.basic.button {
  color: #028090 !important;
  box-shadow: inset 0 0 0 1px #028090;
}

.reg-form__btn2.ui.button:focus,
.reg-form__btn2.ui.button:hover {
  color: #fff !important;
  background-color: #028090 !important;
}

.reg-form__div {
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-bottom: 2em;
}

.reg-form__div,
.reg-form__heading {
  display: flex;
  justify-content: center;
}

.reg-form__heading {
  flex-direction: column;
  color: #028090 !important;
  text-align: center;
  padding: 0 4rem;
  font-size: 1.78571rem;
  line-height: 29px;
}

.reg-form__div--form-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
}

.reg-form__div--input-container {
  width: 80%;
  padding: 0.5rem;
}

.reg-form__group {
  margin-top: 2rem;
}

.reg-form__div--spacer {
  width: 100%;
  height: 1px;
  background-color: initial;
  margin: 16px 0;
}

.reg-form__div--error-msg {
  display: unset !important;
  width: 60%;
}

.reg-form__input--password {
  margin-bottom: 0.3em !important;
}

.reg-form__input--password label {
  color: #028090 !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

.reg-form__checkbox {
  width: 80%;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.reg-form__checkbox-label {
  margin-left: 1rem;
}

.reg-form__checkbox-label a {
  color: #028090;
  text-transform: uppercase;
}

.reg-form .ui.checkbox input:checked~.box:after,
.reg-form .ui.checkbox input:checked~label:after {
  color: #028090 !important;
}

@media screen and (max-width: 850px) {
  .reg-form__form {
    width: 80%;
  }

  .reg-form__div--input-container {
    width: 100%;
  }

  .reg-form__btns {
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .reg-form__checkbox {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .reg-form__form {
    width: 100%;
    padding: 1rem;
  }
}

[class*='reg-form__input']>label {
  color: #028090 !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

.register-welcome {
  height: 100vh;
  padding-top: 6rem;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: linear-gradient(-45deg, #05668d, #00a896, #02c39a);
  background-size: 300% 300%;
  -webkit-animation: gradientBG 8s ease infinite;
  animation: gradientBG 8s ease infinite;
  color: #fff;
}

.register-welcome__header {
  font-size: 3rem;
  font-weight: bolder;
  padding: 1.5em;
}

.register-welcome__body {
  font-size: 1.78571rem;
  line-height: 29px;
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 1.5em;
}

.reply-form__container {
  width: 100%;
  padding-top: 0.4rem;
}

.reply-form__field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reply-form__field img {
  width: 2.76rem;
  margin-right: 0.8rem;
}

.reply-form__text {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  position: relative;
}

.reply-form__text:focus {
  outline: none;
  border-bottom: 2px solid rgba(34, 36, 38, 0.5) !important;
}

.reply-form__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.reply-form__submit {
  margin-right: 1rem !important;
  padding: 0.4rem 1.2rem !important;
}

@media (max-width: 450px) {
  .reply-form__buttons {
    flex-direction: column;
    margin-top: 1.5rem;
  }

  .reply-form__submit {
    margin-bottom: 1rem !important;
  }
}

.signin {
  margin-top: 1.5rem;
}

.signin__form {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #028090;
}

.signin__btn {
  margin: 1rem !important;
  min-width: 100px;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
}

.signin__coil-btn {
  width: 18rem;
  cursor: pointer;
}

.signin__coil-btn:hover {
  opacity: 0.8 !important;
}

.signin__xumm-btn {
  border-radius: 5px !important;
  margin-bottom: 1rem !important;
  padding: 0.6em 2.8em !important;
}

.signin__xumm-btn svg {
  padding-top: 3px;
}

.signin__info {
  width: 80%;
  margin: 0 1rem 1rem !important;
  text-align: center;
  font-size: 1.2rem;
}

.signin__links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem !important;
}

.signin__links a {
  color: #028090;
  text-transform: uppercase;
}

.signin__links a:focus,
.signin__links a:hover {
  font-weight: 700;
}

.signin__heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  padding: 0 4rem;
  color: #028090;
  font-size: 1.78571rem;
  line-height: 29px;
}

.signin__heading i {
  margin-right: 1rem;
  color: #028090;
}

.signin__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #028090;
}

.signin__input-container {
  width: 100%;
  padding: 0.5rem 1rem;
}

.signin__input-container .field>label {
  color: #028090 !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

.signin__divider {
  width: 60%;
}

.signin__divider.ui.section.divider {
  margin: 1rem auto !important;
}

.signin__input--password {
  margin-bottom: 0.3em !important;
}

.signin__input--password label {
  color: #028090 !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

.signin .ui.checkbox input:checked~.box:after,
.signin .ui.checkbox input:checked~label:after,
.signin__input.ui.checkbox label {
  color: #028090 !important;
}

@media (max-width: 700px) {
  .signin__form {
    width: 80%;
  }

  .signin__btn {
    margin: 0.5rem 1rem 0 !important;
  }

  .signin__info {
    margin: 0 0 0.5rem !important;
  }

  .signin__links {
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 1rem 0 0 !important;
  }
}

@media (max-width: 450px) {
  .signin__form {
    width: 100%;
  }
}

.tooltip a {
  color: #028090;
}

.tooltip__icon {
  color: #028090;
  margin-left: 0.4em !important;
}

.verified-icon {
  margin-left: 0.3rem !important;
}

.verified-offset {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

.verified-grey {
  color: rgba(0, 0, 0, 0.1);
}

.verified-primary {
  color: #028090;
}

.verified-white {
  color: #fff;
}

.verified-transparent {
  color: transparent;
}

.verified-hidden {
  display: none !important;
}

.video {
  background-color: #fff !important;
  color: #028090 !important;
  padding: 2rem 0;
}

.video__header {
  font-family: 'Poppins', sans-serif;
  font-size: 2.2rem;
  line-height: 1.5em;
  color: #028090;
  text-align: center;
  padding: 0;
  margin: 0;
}

.video__wrapper {
  padding: 1rem;
}

.video__iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.video__iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video__cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem 0;
}

.video__text {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.5em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding-bottom: 2rem;
}

.video__button {
  background-color: #028090 !important;
  color: #fff !important;
  padding: 0.6rem 3rem !important;
  font-size: 1.5rem !important;
}

.video__button:hover {
  background-color: #01535e !important;
}

.video__button a {
  width: 80%;
  text-transform: uppercase !important;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.welcome {
  width: 100%;
}

.welcome__page-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fff;
  background-color: #028090;
  font-size: 1.71429rem;
  font-weight: 300;
  letter-spacing: 0.6rem;
  padding: 3rem 0 2rem;
}

.welcome hr {
  width: 85%;
}

.welcome section {
  margin: 1.5rem 0;
  padding: 0 2rem 1rem;
}

.welcome section a,
.welcome section h3,
.welcome section h4 {
  color: #028090 !important;
  font-weight: 400 !important;
}

.welcome section h4 {
  padding-top: 0.08rem !important;
}

.welcome section a:hover {
  font-weight: bolder !important;
}

.welcome__text--primary {
  color: #028090 !important;
}

.welcome__image,
.welcome img {
  max-width: 100%;
  padding: 0.6rem 0;
}

.welcome__list {
  -webkit-margin-before: 0.5em !important;
  margin-block-start: 0.5em !important;
  -webkit-margin-after: 0.5em !important;
  margin-block-end: 0.5em !important;
}

.welcome__signup-items {
  padding-left: 0.5em !important;
  list-style: '✅' outside !important;
  padding-bottom: 0.5rem !important;
}

.welcome__docs-list ul {
  list-style: none;
  text-transform: uppercase;
}

.welcome__docs-list ul li {
  margin-top: 0.4rem;
}

.welcome__page-bottom {
  min-height: 2rem;
}

@media (max-width: 330px) {
  .welcome__page-title {
    letter-spacing: 0.3rem;
  }
}

.title {
  border: none;
  margin: calc(2rem - 0.14285714em) 0 1rem;
  padding: 0 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1.28571429em;
  text-transform: none;
  color: rgba(0, 0, 0, .87);
}

.title.large {
  font-size: 1.71428571em;
}

.title:last-child {
  margin-bottom: 0;
}

.subtitle {
  display: block;
  font-weight: 400;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.2em;
  color: rgba(0, 0, 0, .6);
}

h2.title .subtitle,
.title.large .subtitle {
  font-size: 1.14285714rem;
}

.title.dividing {
  padding-bottom: 0.21428571rem;
  border-bottom: 1px solid rgba(34, 36, 38, .15);
}